import React from 'react'
import Nav from '../partials/nav';
import map from '../../images/map.svg';
import supporticon from '../../images/supporticon.svg';
import salesicon from '../../images/salesicon.svg';
import phoneicon from '../../images/phoneicon.svg';
import office from '../../images/office.jpeg';
import Footer from '../partials/footer';

const contact = () => {
  return (

    <div>
<Nav></Nav>

<div className='container'>
    <div className='row'>
        <div className='col-lg-12'>
            <h3 className='contact-header'>We’d love to hear from you.</h3>
            <p className='contact-sentence'>We have offices and teams all around the world.</p>
            <img src={map} className='img-fluid map' alt='map'/>
        </div>
    </div>
</div>


<div className='container frame-padd'>
    <div className='row'>
        <div className='col-md-4'>
<img src={supporticon} className='img-fluid contact-icon' alt='supporticon'/>
<h3 className='intro-header-golden'>Support</h3>
<p className='intro-sentence'>Our friendly team is here to help.</p>
<p className='cc'>support@.goldensolomongroup.com</p>
        </div>
        <div className='col-md-4'>
        <img src={salesicon} className='img-fluid contact-icon' alt='saleicon'/>
        <h3 className='intro-header-golden'>Sales</h3>
<p className='intro-sentence'>Questions or queries? Get in touch!</p>
<p className='cc'>sales@goldensolomongroup.com</p>    
            </div>
            <div className='col-md-4'>
            <img src={phoneicon} className='img-fluid contact-icon' alt='phoneicon'/>
            <h3 className='intro-header-golden'>Phone</h3>
<p className='intro-sentence'>Mon-Fri from 8am to 5pm.</p>
<p className='cc'>+234 (812) 590 9830 </p>    
            </div>
    </div>
</div>

<div className='bac-contact'>
    <div className='row'>
        <div className='col-lg-4'>
<img src={office} className='img-fluid office' alt='office'/>
        </div>

        <div className='col-lg-8'>
    
        <h5 className='add-header'>Our Locations</h5>
        <h3 className='add-sub-header'>Visit our offices</h3>
        <p className='add-sentence'>Find us at these locations.</p>
        <div className='row'>
            <div className='col-lg-4'>
<h5 className='add-location-1'>Lagos</h5>
<p className='add-place-1'>12 Isaac John streetGRA Ikeja Lagos</p>

            </div>
            <div className='col-lg-4'>
            <h5 className='add-location'>Lagos Factory</h5>
            <p className='add-place'>Km3 LASU-Igando road, adjacent General Hospital, Igando, Lagos.</p>         
                </div>

                <div className='col-lg-2'></div>
                <div className='col-lg-2'></div>
       
        </div>      
            </div>
    </div>
</div>

<Footer></Footer>
    </div>
  )
}

export default contact;