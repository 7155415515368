import React from 'react'
import whyis from '../../images/whyis.png';
import costsavingsicon from '../../images/costsavingsicon.svg';
import focusicons from '../../images/focusicons.svg';
import arrow from '../../images/arrow.svg';
import { Link } from 'react-router-dom';


const why = () => {
  return (
    <div>
        <div className='bac-colo-why'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <h3 className='why'>Why choose us</h3>
                    </div>
                </div>
                <div className='row'>
         
                   <div className='col-lg-6'>
                    <div>
<h6 className='why-header'><span><img src={costsavingsicon} className='img-fluid why-icon' alt='costsavings'/></span>Cost Savings</h6>
<p className='why-sentence'>By using our knowledge, technology, and resources, outsourcing your logistics tasks to Golden Solomon Group can help you cut costs and boost efficiency. To get the most out of your business and make the most money, we streamline your operations, cut down on your overhead costs, and make sure your supply chain works at its best.</p>
</div>
<div>
<h6 className='why-header'><span><img src={focusicons} className='img-fluid why-icon' alt='focusicons'/></span>Focus on Core Competencies</h6>
<p className='why-sentence'>If you hire us to handle your logistics, you can use that time and money to focus on what you do best: running your business. You don't have to worry about logistics management. Instead, you can focus on driving innovation, reaching more customers, and growing your business.</p>

<Link to="/why" className="link">
<button className='btn-golden'>Learn More<img src={arrow} className='arrow img-fluid' alt='arrow'/></button>
</Link>
</div>
                    </div>
                    <div className='col-lg-6'>
             <img src={whyis} className='img-fluid hero-image-2' alt='whyis'/>           
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default why;