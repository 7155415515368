import React from "react";
import './App.css';
import {Route, BrowserRouter as Router, Routes} from 'react-router-dom';
import Landing from "./components/pages/landing";
import Contact from "./components/pages/contact";
import Why from "./components/pages/why";
import Ports from "./components/pages/ports";
import Imprts from "./components/pages/imprts";
import Trade from "./components/pages/trade";
import Recycling from "./components/pages/recycling";
import Renew from "./components/pages/renew";
import Procurement from "./components/pages/procurement";



function App() {

  return (
<Router>
<Routes>
  <Route path="/" element={<Landing/>}/>
  <Route path="contact" element={<Contact/>}/>
  <Route path="why" element={<Why/>}/>
  <Route path="ports" element={<Ports/>}/>
  <Route path="imprts" element={<Imprts/>}/>
  <Route path="trade" element={<Trade/>}/>
  <Route path="recycling" element={<Recycling/>}/>
  <Route path="renew" element={<Renew/>}/>
  <Route path="procurement" element={<Procurement/>}/>
 </Routes>
</Router>

  );
}

export default App;
