import React from 'react'
import Nav from '../partials/nav';
import Why from '../partials/why';
import Contactpanel from '../partials/contactpanel';
import Footer from '../partials/footer';


const procurement = () => {
  return (
    <div>

<div className='abstract'>
<Nav></Nav>
<div className='container'>
  <div className='row'>
    <div className='col-lg-12'>
<h3 className='service-trade'>Air & Sea Freight.</h3>
<p className='service-sentence-trade'>Our extensive network, advanced technology, and dedicated team streamline global goods
transportation, ensuring reliability, efficiency, and cost-effectiveness for businesses of all sizes.</p>

<p className='service-sentence-trade'>For expedited delivery, our air freight services offer unmatched speed and precision. We offer
flexible scheduling and competitive rates for urgent transportation needs thanks to our vast airline
and cargo carrier network. Our air freight experts handle perishable, high-value, and time-sensitive
items efficiently and carefully. We always deliver your cargo on time, from customs clearance to
door-to-door.</p>


<p className='service-sentence-trade'>Our sea freight services offer a cost-effective and reliable solution for larger shipments and longer
transit times. We offer a wide range of sea freight options tailored to your needs through strategic
partnerships with major ocean carriers and port operators worldwide. Our sea freight experts
optimise routes, consolidate shipments, and provide end-to-end visibility to ensure smooth
shipping of raw materials, finished products, and complex supply chains. Our transparency,
reliability, and customer satisfaction ensure that we handle your sea freight needs efficiently and
expertly.</p>

<p className='service-sentence-trade'>As each shipment is unique, Golden Solomon offers combined air and sea freight solutions for the
best of both worlds. Our multimodal logistics experts create customised solutions that optimise
transit times, minimise costs, and maximise efficiency for expedited delivery or cost-effective air-
sea transportation. We coordinate seamlessly and pay attention to detail to ensure a smooth
transition between air and sea legs, letting you enjoy both modes of transportation without
compromise.</p>

    </div>

  

  </div>
</div>

</div>


<div className='container'>
  <div className='row'>
    <div className='col-lg-12'>
      <div className='liner'></div>
      <h5 className='header-second'>Logistics Outsourcing</h5>
      <p className='sentence-second'>Businesses are under more and more pressure to improve their supply chain operations.
Outsourcing logistics tasks has become a smart way to do this. We at Golden Solomon are experts
at providing full logistics outsourcing services that will help you streamline your operations, cut
costs, and become more efficient overall.</p>

<p className='sentence-second'>Our full logistics outsourcing services take care of all parts of your supply chain, from buying
things and keeping track of your inventory to shipping, storing, and distributing them. You can
focus on your main business activities while we take care of the complicated logistics management
if you hire us to do these tasks.</p>


<p className='sentence-second'>We know that every business has different needs when it comes to logistics. That's why we offer
solutions that are made to fit your exact needs and goals. No matter if you need full outsourcing
or just help with a few logistics tasks, our team of experts will work with you to make a plan that
meets your needs and goes above and beyond what you expect.</p>


      <div className='liner'></div>
    </div>
  </div>
</div>


<Why></Why>
<Contactpanel></Contactpanel>
<Footer></Footer>
        
    </div>
  )
}

export default procurement;