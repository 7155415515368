import React from 'react'
import heroimage from '../../images/heroimage.jpeg';
import Why from '../partials/why';
import Contactpanel from '../partials/contactpanel';
import Nav from '../partials/nav';
import Footer from '../partials/footer';
import icon1 from '../../images/icon1.svg';
import icon2 from '../../images/icon2.svg';
import icon3 from '../../images/icon3.svg';
import icon4 from '../../images/icon4.svg';
import clientlogo from '../../images/clientlogo.png';
import imports from '../../images/imports.png';
import shippingicon from '../../images/shippingicon.svg';
import exporticon from '../../images/exporticon.svg';
import tradeicon from '../../images/tradeicon.svg';
import plasticicon from '../../images/plasticicon.svg';
import energyicon from '../../images/energyicon.svg';
import internationalicon from '../../images/internationalicon.svg';
import exports from '../../images/exports.png';
import trade from '../../images/trade.png';
import plastic from '../../images/plastic.png';
import energy from '../../images/energy.png';
import international from '../../images/international.png';
import { Link } from 'react-router-dom';

const landing = () => {
  return (
   <div>
<Nav></Nav>
<div className='container'>
    <div className='row'>
        <div className='col-lg-6'>
<h3 className='intro-header'>Welcome to <span className='colored-cut'>Golden Solomon</span> Group.</h3>
<p className='sentence'>your reliable partner for all your import needs! Accessing international markets is no longer a choice in today's connected world; it's a must for businesses that want to grow and stay in business. There are a lot of things that need to be thought about when importing goods, and it can be hard for both new businesses and established ones. This is where we come in.</p>
       <span className='bac-clients-color'>Our Clients</span> <img src={clientlogo} className='img-fluid client-logo' alt='logo'/>
        </div>
        <div className='col-lg-6'>
      <img src={heroimage} className='img-fluid hero-image' alt='hero'/>     
        </div>
    </div>
</div>

<div className='bac-img-about'>
<div className='container'>
    <div className='row'>
        <div className='col-lg-12'>
            <h4 className='about-us'>About Us</h4>
            </div>
    </div>

    <div className='row'>
        <div className='col-lg-6'>
            <img src={icon1} className='img-fluid icon-a' alt='icon-1'/>
            <p className='sentence-fluid'>As a global company based in Lagos Nigeria, Golden Solomon Group is exceptionally positioned to serve both international and domestic customers in the world’s fastest growing markets.</p>
        </div>

        <div className='col-lg-6'>
            <img src={icon2} className='img-fluid icon-a' alt='icon-2'/>
            <p className='sentence-fluid'>Our experienced and dedicated professionals provide companies with premium international shipping and logistics solutions.</p>
        </div>

        <div className='col-lg-6'>
            <img src={icon3} className='img-fluid icon-a' alt='icon-3'/>
            <p className='sentence-fluid'>As a leading company in export and import, We provide advanced solutions through our value-creating services that will allow companies to make the right moves for their business.</p>
        </div>

        <div className='col-lg-6'>
            <img src={icon4} className='img-fluid icon-a' alt='icon-4'/>
            <p className='sentence-fluid'>Our goal is to create the constant advancement producing quality services through our ongoing commitment to technological innovations. We are devoted to providing unwavering and high-quality shipping and logistics solutions to ensure the consistent satisfaction of our customers all the time.</p>
        </div>

    </div>
     {/**about the business**/}

 <h3 className='business-header'>It's all in your <span className='colored-cut'>business</span> interests</h3>
 <p className='sentence'>Our Top star services include all of the following discussed below.</p>
<div className='row'>
<div className='col-lg-6'>
    <div className='card-golden'>
    <img src={shippingicon} className='img-fluid service-icon' alt='imports'/>
        <div className='wrapper'>
    <h6 className='card-header'>Imports</h6>
    <p className='card-sentence'>At Golden Solomon, we offer a wide range of import services that can be customised to fit the needs of your business.  </p>
    <span className='card-call-to-action'><Link to="/imprts" class="card-call-to-action">Learn More</Link></span>
    </div>
    <img src={imports} className='img-fluid golden-card-img' alt='imports'/>
    </div>
</div>

<div className='col-lg-6'>
<div className='card-golden'>
    <img src={exporticon} className='img-fluid service-icon' alt='imports'/>
        <div className='wrapper'>
    <h6 className='card-header'>Export</h6>
    <p className='card-sentence'>At Golden Solomon, we believe exports can expand horizons, drive growth, and promote prosperity.</p>
    <span className='card-call-to-action'><Link to="/ports" class="card-call-to-action">Learn More</Link></span>
    </div>
    <img src={exports} className='img-fluid golden-card-img' alt='exports'/>
    </div>
</div>
<div className='col-lg-6'>
<div className='card-golden'>
    <img src={tradeicon} className='img-fluid service-icon' alt='imports'/>
        <div className='wrapper'>
    <h6 className='card-header'>Structured Trade Finance Solutions</h6>
    <p className='card-sentence'>Here at Golden Solomon, we revolutionize trade with innovative financial solutions for your
business.</p>
    <span className='card-call-to-action'> <Link to="/trade" class="card-call-to-action"> Learn More </Link> </span>
    </div>
    <img src={trade} className='img-fluid golden-card-img' alt='trade'/>
    </div>
</div>

<div className='col-lg-6'>
<div className='card-golden'>
    <img src={plasticicon} className='img-fluid service-icon' alt='imports'/>
        <div className='wrapper'>
    <h6 className='card-header'>Plastic Recycling</h6>
    <p className='card-sentence'>We fight plastic pollution, conserve
    natural resources, and build a greener future for future generations. </p>
    <span className='card-call-to-action'> <Link to="/recycling" class="card-call-to-action"> Learn More </Link> </span>
    </div>
    <img src={plastic} className='img-fluid golden-card-img' alt='plastic'/>
    </div>
</div>
<div className='col-lg-6'>
<div className='card-golden'>
    <img src={energyicon} className='img-fluid service-icon' alt='imports'/>
        <div className='wrapper'>
    <h6 className='card-header'>Renewable Energy</h6>
    <p className='card-sentence'>At Golden Solomon, we believe renewable energy can create a sustainable future. Renewable
    energy is important to the world. </p>
    <span className='card-call-to-action'><Link to="/renew" class="card-call-to-action">Learn More</Link></span>
    </div>
    <img src={energy} className='img-fluid golden-card-img' alt='energy'/>
    </div>
</div>
<div className='col-lg-6'>
<div className='card-golden'>
    <img src={internationalicon} className='img-fluid service-icon' alt='imports'/>
        <div className='wrapper'>
    <h6 className='card-header'>International Procurement</h6>
    <p className='card-sentence'>We are very much perculiar about of Air and sea freight, Fruits and Juice Concentrates, Logistics & warehousing </p>
    <span className='card-call-to-action'><Link to="/procurement" class="card-call-to-action">Learn More</Link></span>
    </div>
    <img src={international} className='img-fluid golden-card-img' alt='international'/>
    </div>
</div>
 </div>
</div>

</div>

<Why></Why>
<Contactpanel></Contactpanel>
<Footer></Footer>

   </div>
  )
}

export default landing;