import React from 'react'
import Nav from '../partials/nav';
import Why from '../partials/why';
import Contactpanel from '../partials/contactpanel';
import Footer from '../partials/footer';
import metigation from '../../images/metigation.svg';
import stream from '../../images/stream.svg';
import globalreach from '../../images/globalreach.svg';
import liquid from '../../images/liquid.svg';




const trade = () => {
  return (
    <div>
<div className='abstract'>
<Nav></Nav>
<div className='container'>
  <div className='row'>
    <div className='col-lg-12'>
<h3 className='service-trade'>Our Structured trading solutions</h3>
<p className='service-sentence-trade'>Here at Golden Solomon, we revolutionize trade with innovative financial solutions for your
business. Our structured trade finance solutions streamline transactions, reduce risk, and unlock
global growth.</p>

    </div>

  

  </div>
</div>

</div>


<div className='container'>
  <div className='row'>
    <div className='col-lg-12'>
      <div className='liner'></div>
      <h5 className='header-second'>What is Structured Trade Finance?</h5>
      <p className='sentence-second'>Structured trade finance lets companies conduct complex international trade transactions. It offers
      pre-export, supply chain, and commodity trading finance to meet trade deal needs.</p>
      <div className='liner'></div>
    </div>
  </div>
</div>


<div className='container'>
  <div className='row'>
    <div className='col-lg-12'>
    <h5 className='header-second-2'>Our Structured Trade Finance Solutions—Why?</h5>    
    <p className='sentence-second'>We offer customized financing because every trade transaction is different. We tailor our
structured trade finance solutions to your needs, whether you're a small importer or a multinational
corporation.</p>
    </div>
  </div>
</div>

<div className='container'>
  <div className='row'>

  <div className='col-lg-6'>
<img src={metigation} className='img-fluid whyicon' alt='why'/>
<h3 className='why-heading'>Risk Mitigation</h3>
<p className='why-sentence-main'>Currency fluctuations, geopolitical instability, and credit risks are inherent in international trade.
Risk mitigation strategies protect your transactions and ensure smooth execution in our structured
trade finance solutions.</p>
        </div>

        <div className='col-lg-6'>
<img src={liquid} className='img-fluid whyicon' alt='why'/>
<h3 className='why-heading'>Enhanced Liquidity</h3>
<p className='why-sentence-main'>Our structured trade finance solutions optimize working capital, cash flow, and liquidity to fuel
business growth and expansion.</p>
        </div>


        <div className='col-lg-6'>
<img src={globalreach} className='img-fluid whyicon' alt='why'/>
<h3 className='why-heading'>Global Reach</h3>
<p className='why-sentence-main'>Our extensive network of financial institutions, strategic partners, and industry experts gives you
access to global markets and opportunities to expand your trade footprint and reach new customers.</p>
        </div>

        <div className='col-lg-6'>
<img src={stream} className='img-fluid whyicon' alt='oo'/>
<h3 className='why-heading'>Process Streamlining</h3>
<p className='why-sentence-main'>We reduce paperwork, bureaucracy, and transaction times in trade finance. Our efficient and
transparent approach ensures a smooth experience for all.</p>
        </div>


  </div>
</div>

<div className='container'>
  <div className='row'>
    <div className='col-lg-12'>
      <div className='liner'></div>
      <h5 className='header-second'>Documentation & Consultancy</h5>
      <p className='sentence-second'>Navigate international trade with Golden Solomon, your trusted partner. Our comprehensive
documentation and consultancy services streamline global trading, helping businesses expand their
reach and maximize global market opportunities.</p>

<p className='sentence-second'>International trade relies on documentation, and our experts ensure compliance, accuracy, and
efficiency at every stage. From customs clearance and import/export licences to trade agreements
and certificates of origin, we handle all documentation carefully. By staying current on regulations
and requirements, we help businesses navigate the complex web of international trade regulations.</p>

<p className='sentence-second'>Beyond documentation, our consultancy services provide strategic insights and customized
solutions to optimize international trade operations. No matter your experience in global trade, our
team can help you with market entry, supply chain optimization, risk management, and more. Our
industry knowledge and global network help businesses mitigate risks, seize opportunities, and
stay ahead in today's dynamic global market.</p>

<p className='sentence-second'>We customise our consultancy services because we know every business is
different. Our team collaborates with clients to understand their needs, challenges, and goals and
create custom solutions that boost international trade. We have the skills and resources to help you
expand your market, optimise costs, and comply with regulations.</p>

<p className='sentence-second'>You can trust Golden Solomon to handle your international trade operations. From documentation
to consultancy, we provide excellent service, value, and results for your business. We can help you
confidently and efficiently navigate global trade.</p>
      <div className='liner'></div>
    </div>
  </div>
</div>


<div className='container'>
  <div className='row'>
    <div className='col-lg-12'>
    <h5 className='header-second-2'>Structured Trade Finance Options</h5>    
    </div>
  </div>
</div>

<div className='container'>
  <div className='row'>

  <div className='col-lg-6'>

<h3 className='why-heading'>Pre-export Finance</h3>
<p className='why-sentence-main'>Get financing to buy raw materials, manufacture goods, and fulfil orders before shipment to seize
opportunities and meet customer demands.</p>
        </div>

        <div className='col-lg-6'>

<h3 className='why-heading'>Supply Chain Financing</h3>
<p className='why-sentence-main'>Customized financing solutions that address working capital gaps and improve liquidity across the
value chain improve supply chain efficiency and supplier-buyer relationships.</p>
        </div>


        <div className='col-lg-6'>

<h3 className='why-heading'>Commodity Trading Finance</h3>
<p className='why-sentence-main'>Use our structured finance expertise to manage price risks, secure purchase financing, and
maximize profitability in volatile markets.</p>
        </div>

        <div className='col-lg-6'>

<h3 className='why-heading'>Trade Credit Insurance</h3>
<p className='why-sentence-main'>Comprehensive trade credit insurance solutions protect your trade receivables from non-payment
risks, providing peace of mind and financial security in uncertain times.</p>
        </div>

  </div>
</div>




<Why></Why>
<Contactpanel></Contactpanel>
<Footer></Footer>


    </div>
  )
}

export default trade;