import React from 'react'
import Nav from '../partials/nav';
import Why from '../partials/why';
import Contactpanel from '../partials/contactpanel';
import Footer from '../partials/footer';
import energy from '../../images/energy.jpg';

const renew = () => {
  return (
    <div>


<div className='abstract'>
<Nav></Nav>
<div className='container'>
  <div className='row'>
    <div className='col-lg-12'>
<h3 className='service-trade'>Renewable Energy</h3>
<p className='service-sentence-trade'>At Golden Solomon, we believe renewable energy can create a sustainable future. Renewable
energy is more important than ever as the world faces environmental issues. Our goal is to provide
innovative energy solutions that meet today's needs and protect the planet for future generations.
Renewable energy includes solar, wind, hydro, biomass, and geothermal. These resources are
essential to a low-carbon economy because they are replenished and emit little greenhouse gas.</p>
<img src={energy} className='img-fluid img-plastic' alt='energy'/>

<p className='service-sentence-trade'>Solar power leads our renewable energy efforts. Clean, efficient electricity can be generated from
the sun's abundant energy. Our cutting-edge solar technologies help businesses and communities
reduce their carbon footprint and enjoy reliable energy.</p>

<p className='service-sentence-trade'>Wind energy is another renewable staple. We generate large amounts of electricity from wind
turbines worldwide. We aim to maximise wind energy and drive a more sustainable energy mix
through strategic partnerships and innovative turbine designs.</p>

<p className='service-sentence-trade'>Hydropower, biomass, and geothermal energy enhance our renewable energy. We diversify our
energy sources and reduce environmental impact by using water, bioenergy, and Earth's heat.</p>

<p className='service-sentence-trade'>Our commitment to renewable energy goes beyond technology. We foster partnerships, promote
energy efficiency, and empower communities to adopt sustainable practices. With renewable
energy, we can create a greener future.</p>

    </div>

  

  </div>
</div>

</div>



<Why></Why>
<Contactpanel></Contactpanel>
<Footer></Footer>


    </div>
  )
}

export default renew;