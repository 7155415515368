import React from 'react'
import callicon from '../../images/callicon.svg';

const contactpanel = () => {
  return (
    <div>
        <div className='contact-background'>
           
        <div className='container'>
        <div className='contact-background-coprate'>
            <div className='row'>
                <div className='col-lg-12'>
                    <h3 className='header-contact'>We would like to hear from you</h3>
                    <p className='sentence-contact'>For enquires on our services and how we can help your business, Kindly request a call back; we would get in touch Immediately .</p>
                </div>
            </div>

<div className='row'>
    <div className='col-md-6'>
        <input type='text' placeholder='Full Name'></input>
    </div>
    <div className='col-md-6'>
        <input type='text' placeholder='Mobile Number'></input>
    </div>
    <div className='col-md-6'>
        <input type='text' placeholder='Select a service'></input>
    </div>
    <div className='col-md-6'>
        <button className='btn-contact'><img src={callicon} className='callicon' alt='callicon'/>Request a call back</button>
    </div>
</div>

            </div>        
            </div>

        </div>
    </div>
  )
}

export default contactpanel;