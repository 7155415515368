import React from 'react'
import linkedin from '../../images/linkedin.svg';
import { Link } from 'react-router-dom';

const footer = () => {
  return (
    <div>
        <div className='background-footer'>

        <div className='container'>
            <div className='row'>
                <div className='col-md-3'>

    <h6 className='footer-header'>Our company</h6>
   
    <li className='li-2'>Golden Solomon Link. Nig Ltd</li>
    <li className='li-2'>Golden Solomon Capital Investment Ltd</li>
    <li className='li-2'>DeUnique Tradecom.GS Ltd</li>
    <li className='li-2'>Portrait-FX Bureau De Change Ltd</li>
    <li className='li-2'>Gatrading Ltd</li>

                </div>

                <div className='col-md-3'>
                <h6 className='footer-header'>Services</h6>    
                
    <li className='li-2'><Link to="/ports" class="li-2">Export</Link></li>
    <li className='li-2'><Link to="/imprts" class="li-2">Import</Link></li>
    <li className='li-2'><Link to="/trade" class="li-2">Structured trade finance</Link></li>
    <li className='li-2'><Link to="/recycling" class="li-2">Plastic Recycling</Link></li>
    <li className='li-2'><Link to="/renew" class="li-2">Renewable energy</Link></li>
    <li className='li-2'><Link to="/procurement" class="li-2">International Procurement</Link></li>

             
                    </div>

                    <div className='col-md-3'>
                    <h6 className='footer-header'>Quick find</h6>      
            <Link to="/contact" className='link'>        
    <li className='li-2'>Contact Us </li>
    </Link>

                    </div>

                    <div className='col-md-3'>
                    <h6 className='footer-header'>Connect with us on social media</h6>  
                    <Link to ="https://www.linkedin.com/company/golden-solomon-group/" target='_blank'>    
                    <img src={linkedin} className='img-fluid' alt='linkedin'/>
                    </Link>
                    </div>
            </div>
        </div>

        

        <div className='container'>
        <div className='line'></div>
        <div className='row'>
            <div className='col-md-6'>
                <p className='rights'> ©️ 2024 Golden Solomon Group | All Rights Reserved. 12 Isaac John Street,GRA, Ikeja, Lagos Nigeria.</p>
                <span className='termsof'>Privacy Policy | </span>
                <span className='termsof'>Terms of Business</span>
            </div>

            <div className='col-md-6'>
                <p className='rights'>For product sales inquiries or services, email <span className='mail-footer'>contact@goldensolomongroup.com</span> or call +234 (812) 590 9830</p>
                <p className='rights'>For General Enquires email <span className='mail-footer'>info@goldensolomongroup.com</span> or call +234 (812) 590 9830</p>
              
            </div>
        </div>
        </div>

        </div>
    </div>
  )
}

export default footer;