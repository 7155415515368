import React from 'react'
import Nav from '../partials/nav';
import Why from '../partials/why';
import Contactpanel from '../partials/contactpanel';
import Footer from '../partials/footer';
import clientlogo from '../../images/clientlogo.png';
import expor from '../../images/expor.png';
import quality from '../../images/quality.svg';
import portfolio from '../../images/portfolio.svg';
import globals from '../../images/globals.svg';
import excellent from '../../images/excellent.svg';
import process from '../../images/process.svg';
import Sustainability from '../../images/Sustainability.svg';
import adaptation from '../../images/adaptation.svg';
import logistics from '../../images/logistics.svg';
import documentation from '../../images/documentation.svg';
import support from '../../images/support.svg';


const ports = () => {
  return (
    <div>



<div className='abstract'>
<Nav></Nav>
<div className='container'>
  <div className='row'>
    <div className='col-lg-6'>
    <span className='service-tags'>Exports</span>
<h3 className='service-header'>We believe exports can expand horizons.</h3>
<p className='service-sentence'>At Golden Solomon, we believe exports can expand horizons, drive growth, and promote prosperity. With a rich product portfolio and a commitment to excellence, we connect with global markets, deliver quality, and build lasting partnerships.</p>
<span className='bac-clients-color'>Our Clients</span> <img src={clientlogo} className='img-fluid client-logo' alt='logo'/>
    </div>

    <div className='col-lg-6'>
     <img src={expor} className='img-fluid expor' alt='expor'/>
      </div>


  </div>
</div>

</div>

<div className='container'>
  <div className='row'>
    <div className='col-lg-12'>
      <div className='liner'></div>
      <h5 className='header-second'>Our Export Philosophy</h5>
      <p className='sentence-second'>Exporting is about sharing our vision, values, and expertise, not just selling products. Exporting is a symbol of Golden Solomon's commitment to innovation, quality, and customer satisfaction.We're proud to showcase our finest products, cutting-edge technology, and innovative solutions worldwide.</p>
      <div className='liner'></div>
    </div>
  </div>
</div>

<div className='container'>
  <div className='row'>
    <div className='col-lg-12'>
    <h5 className='header-second-2'>Why Export with Us?</h5>    
    </div>
  </div>
</div>

<div className='container'>
  <div className='row'>

  <div className='col-lg-6'>
<img src={quality} className='img-fluid whyicon' alt='why'/>
<h3 className='why-heading'>Quality Assurance</h3>
<p className='why-sentence-main'>Every Golden Solomon product reflects our dedication to quality. To ensure quality from raw material sourcing to manufacturing and distribution, we follow strict quality standards.</p>
        </div>

        <div className='col-lg-6'>
<img src={portfolio} className='img-fluid whyicon' alt='why'/>
<h3 className='why-heading'>Diverse Portfolio</h3>
<p className='why-sentence-main'>Our diverse portfolio spans industries, offering a wide range of products to meet diverse market needs. We have consumer goods, technology, and industrial solutions for everyone.</p>
        </div>


        <div className='col-lg-6'>
<img src={globals} className='img-fluid whyicon' alt='why'/>
<h3 className='why-heading'>Global Reach</h3>
<p className='why-sentence-main'>We are strong in global markets thanks to our extensive network of distributors, partners, and agents. Our products reach customers worldwide, opening new markets and opportunities</p>
        </div>

        <div className='col-lg-6'>
<img src={excellent} className='img-fluid whyicon' alt='why'/>
<h3 className='why-heading'>Excellent Customer Service</h3>
<p className='why-sentence-main'>Everything we do at Golden Solomon is customer-focused. We tailor solutions to their needs, preferences, and challenges to exceed expectations and add value.</p>
        </div>

        <div className='col-lg-6'>
<img src={Sustainability} className='img-fluid whyicon' alt='why'/>
<h3 className='why-heading'>Sustainability</h3>
<p className='why-sentence-main'>We prioritize sustainability and responsible business. All of our operations prioritize environmental and social responsibility, from eco-friendly production to ethical sourcing.</p>
        </div>


        <div className='col-lg-6'>
<img src={process} className='img-fluid whyicon' alt='why'/>
<h3 className='why-heading'>The Export Process</h3>
<p className='why-sentence-main'>We conduct extensive market research to identify promising markets, consumer trends, and competition.</p>
        </div>


        <div className='col-lg-6'>
<img src={adaptation} className='img-fluid whyicon' alt='why'/>
<h3 className='why-heading'>Product adaptation</h3>
<p className='why-sentence-main'>We tailor our products to target markets' tastes and needs for maximum relevance and appeal.</p>
        </div>


        <div className='col-lg-6'>
<img src={logistics} className='img-fluid whyicon' alt='why'/>
<h3 className='why-heading'>Logistics and Distribution</h3>
<p className='why-sentence-main'>Our efficient logistics network ensures timely delivery and seamless distribution worldwide.</p>
        </div>


        <div className='col-lg-6'>
<img src={documentation} className='img-fluid whyicon' alt='why'/>
<h3 className='why-heading'>Compliance and Documentation</h3>
<p className='why-sentence-main'>We navigate international trade regulations and comply with all standards and certifications.</p>
        </div>


        <div className='col-lg-6'>
<img src={support} className='img-fluid whyicon' alt='why'/>
<h3 className='why-heading'>Support after the sale</h3>
<p className='why-sentence-main'>We're committed to customer satisfaction. Our after-sales support includes warranty, technical, and troubleshooting.</p>
        </div>


  </div>
</div>

<Why></Why>
<Contactpanel></Contactpanel>
<Footer></Footer>

    </div>
  )
}

export default ports;