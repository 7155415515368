import React from 'react'
import callicon from '../../images/callicon.svg';
import drop from '../../images/drop.svg';
import { Link } from 'react-router-dom';
import logo from '../../images/logo.png';
import hamburger from '../../images/hamburger.png';

const nav = () => {
  return (
    <div className='sticky-top'>
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
  <div className="container">
    <span className="navbar-brand"><Link to="/"><img src={logo} className='img-fluid logo' alt='logo'/></Link></span>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
      <span><img src={hamburger} className='img-fluid hamburger' alt='hamburger'/></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarText">
    
      <ul className=" me-auto mb-2 mb-lg-0">
      <div className='menu-padding navbar-nav'>
        <li className="nav-item">
            <Link to ="/why" className='link'>
          <span className="nav-link " aria-current="page">Why Us?</span>
          </Link>
        </li>
        
        <li class="nav-item dropdown">
          <span class="nav-link" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
           <span> Services <img src={drop} className='img-fluid' alt='drop-icon'/> </span>
          </span>
          <ul class="dropdown-menu">
            <li><Link to="/ports" class="dropdown-item">Export</Link></li>
            <li><Link to="/imprts" class="dropdown-item">Import</Link></li>
            <li><Link to="/trade" class="dropdown-item">Structured trade finance</Link></li>
            <li><Link to="/recycling" class="dropdown-item">Plastic Recycling</Link></li>
            <li><Link to="/renew" class="dropdown-item">Renewable Energy</Link></li>
            <li><Link to="/procurement" class="dropdown-item">International Procurement</Link></li>
          </ul>
        </li>


        <li class="nav-item dropdown">
          <span class="nav-link" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        <span> Company <img src={drop} className='img-fluid' alt='drop-icon'/> </span>
          </span>
          <ul class="dropdown-menu">
            <li><span class="dropdown-item" href="#">Golden Solomon Link. Nig Ltd</span></li>
            <li><span class="dropdown-item" href="#">Golden Solomon Capital Investment Ltd</span></li>
            <li><span class="dropdown-item" href="#">DeUnique Tradecom.GS Ltd</span></li>
            <li><span class="dropdown-item" href="#">Portrait-FX Bureau De Change Ltd</span></li>
            <li><span class="dropdown-item" href="#">Gatrading Ltd</span></li>
     
          </ul>
        </li>
        </div>

      </ul>
     
      <span className="navbar-text">
        <Link to ="/contact">
      <button className='nav-button-golden'><img src={callicon} className='callicon-2' alt='callicon'/>Contact Us</button>
      </Link>
      </span>
    </div>
  </div>
</nav>
    </div>
  )
}

export default nav;