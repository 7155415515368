import React from 'react'
import Nav from '../partials/nav';
import Why from '../partials/why';
import Contactpanel from '../partials/contactpanel';
import Footer from '../partials/footer';
import clientlogo from '../../images/clientlogo.png';
import expor from '../../images/expor.png';
import expert from '../../images/expert.svg';
import supplier from '../../images/supplier.svg';
import customs from '../../images/customs.svg';
import oo from '../../images/oo.svg';
import risk from '../../images/risk.svg';
import optimization from '../../images/optimization.svg';
import support from '../../images/support.svg';

const imprts = () => {
  return (
    <div>

<div className='abstract'>
<Nav></Nav>
<div className='container'>
  <div className='row'>
    <div className='col-lg-6'>
    <span className='service-tags'>Imports</span>
<h3 className='service-header'>We believe imports can expand horizons.</h3>
<p className='service-sentence'>At Golden Solomon, we offer a wide range of import services that can be customized to fit the
needs of your business. With years of experience and a global network of partners, we've
streamlined the import process to make sure that all transactions go smoothly and goods are always
delivered on time.</p>
<span className='bac-clients-color'>Our Clients</span> <img src={clientlogo} className='img-fluid client-logo' alt='logo'/>
    </div>

    <div className='col-lg-6'>
     <img src={expor} className='img-fluid expor' alt='expor'/>
      </div>


  </div>
</div>

</div>



<div className='container'>
  <div className='row'>
    <div className='col-lg-12'>
    <h5 className='header-second-2'>Here's how we can make your journey of importing better:</h5>    
    </div>
  </div>
</div>

<div className='container'>
  <div className='row'>

  <div className='col-lg-6'>
<img src={expert} className='img-fluid whyicon' alt='why'/>
<h3 className='why-heading'>Expert Advice</h3>
<p className='why-sentence-main'>At every step of the import process, our team of experienced professionals is here to help you.
We're here to make sure you follow the rules and avoid problems as much as possible, from
understanding customs rules to finding the best shipping routes.</p>
        </div>

        <div className='col-lg-6'>
<img src={supplier} className='img-fluid whyicon' alt='why'/>
<h3 className='why-heading'>Supplier Sourcing</h3>
<p className='why-sentence-main'>If you want to import goods, you need to find suppliers you can trust. We use our large network
to put you in touch with reliable suppliers around the world, so you can be sure you'll get good
products at good prices.</p>
        </div>


        <div className='col-lg-6'>
<img src={customs} className='img-fluid whyicon' alt='why'/>
<h3 className='why-heading'>Customs Clearance</h3>
<p className='why-sentence-main'>It can be hard to figure out how to go through the customs clearance process. Our skilled customs
brokers take care of all the paperwork and clearance quickly and easily, saving you time and
trouble.</p>
        </div>

        <div className='col-lg-6'>
<img src={oo} className='img-fluid whyicon' alt='oo'/>
<h3 className='why-heading'>Logistics Management</h3>
<p className='why-sentence-main'>For on-time delivery, logistics must work well. We work closely with reputable goods forwarders
and carriers to find the best shipping routes and cut down on transit times. This saves you time and
money.</p>
        </div>

        <div className='col-lg-6'>
<img src={risk} className='img-fluid whyicon' alt='why'/>
<h3 className='why-heading'>Risk Reduction</h3>
<p className='why-sentence-main'>There are risks that come with importing, such as changes in the value of the currency and
unpredictability in world politics. Our risk management strategies help lower these risks, keeping
your investments safe and making sure everything runs smoothly.</p>
        </div>


        <div className='col-lg-6'>
<img src={optimization} className='img-fluid whyicon' alt='why'/>
<h3 className='why-heading'>Optimization of Costs</h3>
<p className='why-sentence-main'>We know how important it is to optimize costs in today's competitive market. We help you
maximize your bottom line by negotiating and strategic sourcing to keep costs low without
lowering quality.</p>
        </div>


        <div className='col-lg-6'>
<img src={support} className='img-fluid whyicon' alt='why'/>
<h3 className='why-heading'>Support that lasts</h3>
<p className='why-sentence-main'>We want you to succeed, and that doesn't end with the transaction. We offer ongoing support and
advice to make sure that your importing businesses are successful and last for a long time.

Working with us, you can open up a lot of doors for your business. We have the knowledge and
tools to make your importing process smooth and easy, whether you're bringing in raw materials,
finished goods, or machinery.
</p>
        </div>


  </div>
</div>

<Why></Why>
<Contactpanel></Contactpanel>
<Footer></Footer>


    </div>
  )
}

export default imprts;