import React from 'react'
import Nav from '../partials/nav';
import Why from '../partials/why';
import Contactpanel from '../partials/contactpanel';
import Footer from '../partials/footer';
import technology from '../../images/technology.svg';
import stream from '../../images/stream.svg';
import enviromental from '../../images/enviromental.svg';
import economy from '../../images/economy.svg';
import certification from '../../images/certification.svg';
import plastic from '../../images/plastic.jpg';

const recycling = () => {
  return (
    <div>

<div className='abstract'>
<Nav></Nav>
<div className='container'>
  <div className='row'>
    <div className='col-lg-12'>
<h3 className='service-trade'>Plastic Recycling</h3>
<p className='service-sentence-trade'>Golden Solomon is your sustainable plastic recycling partner. We fight plastic pollution, conserve
natural resources, and build a greener future for future generations. We're pioneering plastic waste
recycling with our cutting-edge technology and methods.</p>
<img src={plastic} className='img-fluid img-plastic' alt='plastic'/>

    </div>

  

  </div>
</div>

</div>


<div className='container'>
  <div className='row'>
    <div className='col-lg-12'>
      <div className='liner'></div>
      <h5 className='header-second'>Our Plastic Recycling Method</h5>
      <p className='sentence-second'>Golden Solomon considers plastic recycling a responsibility, not a solution. Everything from
collection and sorting to processing and manufacturing is included in our plastic recycling strategy.
We use cutting-edge technology and best practices to maximize efficiency, reduce environmental
impact, and create a plastics circular economy.</p>
      <div className='liner'></div>
    </div>
  </div>
</div>


<div className='container'>
  <div className='row'>
    <div className='col-lg-12'>
    <h5 className='header-second-2'>Why Use Our Plastic Recycling Services?</h5>    
  
    </div>
  </div>
</div>

<div className='container'>
  <div className='row'>

  <div className='col-lg-6'>
<img src={technology} className='img-fluid whyicon' alt='why'/>
<h3 className='why-heading'>Advanced Technology</h3>
<p className='why-sentence-main'>We use cutting-edge recycling equipment to maximize quality and resource recovery. We use
innovative methods to recycle plastic waste into high-quality materials, from mechanical to
chemical.</p>
        </div>

        <div className='col-lg-6'>
<img src={enviromental} className='img-fluid whyicon' alt='why'/>
<h3 className='why-heading'>Environmental Impact</h3>
<p className='why-sentence-main'>Plastic pollution threatens ecosystems and biodiversity. Recycling plastic waste reduces demand
for virgin plastics, conserves natural resources, and reduces plastic production and disposal's
environmental impact.</p>
        </div>


        <div className='col-lg-6'>
<img src={economy} className='img-fluid whyicon' alt='why'/>
<h3 className='why-heading'>Circular Economy</h3>
<p className='why-sentence-main'>We follow the circular economy, which recycles and reuses materials indefinitely to reduce waste
and maximize resource efficiency. Our plastic recycling solutions help create a sustainable,
regenerative economy.</p>
        </div>

        <div className='col-lg-6'>
<img src={stream} className='img-fluid whyicon' alt='oo'/>
<h3 className='why-heading'>Individualized Solutions</h3>
<p className='why-sentence-main'>We know every customer and plastic waste stream is different. Our recycling solutions are tailored
to your needs as a manufacturer, retailer, or waste management company.</p>
        </div>

        <div className='col-lg-6'>
<img src={certification} className='img-fluid whyicon' alt='oo'/>
<h3 className='why-heading'>Compliance and Certifications</h3>
<p className='why-sentence-main'>Our plastic recycling facilities meet the highest industry and regulatory standards. We prioritise
transparency, compliance, and continuous improvement in all our operations.</p>
        </div>


  </div>
</div>

<div className='container'>
  <div className='row'>
    <div className='col-lg-12'>
      <div className='liner'></div>
      <h5 className='header-second'>Our Plastic Recycling Process</h5>
      <p className='sentence-second'>We work with waste management partners to collect and sort plastic waste from various sources
      to ensure a steady supply for our recycling facilities.</p>

<p className='sentence-second'>Cleaning, shredding, melting, and refining plastic waste into high-quality recycled materials for
manufacturing uses involves mechanical and chemical processes.</p>

<p className='sentence-second'>Our rigorous quality control measures ensure that our recycled materials meet or exceed industry
purity, strength, and performance standards. Our products undergo extensive testing and analysis
to ensure quality and integrity.</p>

<p className='sentence-second'>Packaging, automotive components, construction materials, and consumer goods use our recycled
materials as raw materials.</p>

      <div className='liner'></div>
    </div>
  </div>
</div>






<Why></Why>
<Contactpanel></Contactpanel>
<Footer></Footer>


    </div>
  )
}

export default recycling;