import React from 'react'
import Nav from '../partials/nav';
import Footer from '../partials/footer';
import whypics from '../../images/whypics.png';
import cost from '../../images/cost.svg';
import customer from '../../images/customer.svg';
import focus from '../../images/focus.svg';
import global from '../../images/global.svg';
import reliability from '../../images/reliability.svg';
import compliance from '../../images/compliance.svg';
import expertise from '../../images/expertise.svg';
import personalized from '../../images/personalized.svg';
import investing from '../../images/investing.svg';
import expanding from '../../images/expanding.svg';
import means from '../../images/means.svg';
import commitment from '../../images/commitment.svg';
import chi from '../../images/chi.png';
import wandel from '../../images/wandel.png';
import courtyard from '../../images/courtyard.png';
import fmn from '../../images/fmn.png';
import pro from '../../images/pro.png';
import fries from '../../images/fries.png';
import olam from '../../images/olam.png';

const why = () => {
  return (
 
    <div>
   <Nav></Nav>
<div className='bac-why'>
   <div className='container'>
    <div className='row'>
        <div className='col-lg-12'>
            <h3 className='contact-header'>Why Us?</h3>
            <p className='contact-sentence'>We are exceptional to your Business growth</p>
           </div>
    </div>
</div>
<img src={whypics} className='img-fluid whypics' alt='whypics'/>
</div>

<div className='container'>
    <div className='row'>

        <div className='col-lg-6'>
<img src={cost} className='img-fluid whyicon' alt='why'/>
<h3 className='why-heading'>Cost Savings</h3>
<p className='why-sentence-main'>By using our knowledge, technology, and resources, outsourcing your logistics tasks to Golden Solomon Group can help you cut costs and boost efficiency. To get the most out of your business and make the most money, we streamline your operations, cut down on your overhead costs, and make sure your supply chain works at its best.</p>
        </div>

        <div className='col-lg-6'>
        <img src={customer} className='img-fluid whyicon' alt='why'/>
<h3 className='why-heading'>Customer-centric Approach</h3>
<p className='why-sentence-main'>Your happiness is the most important thing to us. We are dedicated to giving our customers the best service and support possible, from the first consultation to ongoing partnership and collaboration. As a logistics outsourcing partner,Golden Solomon Group, you can be sure that your logistics work is being done well.</p>
    </div>

    <div className='col-lg-6'>
<img src={focus} className='img-fluid whyicon' alt='why'/>
<h3 className='why-heading'>Focus on Core Competencies</h3>
<p className='why-sentence-main'>If you hire us to handle your logistics, you can use that time and money to focus on what you do best: running your business. You don't have to worry about logistics management. Instead, you can focus on driving innovation, reaching more customers, and growing your business.</p>
        </div>

        <div className='col-lg-6'>
<img src={global} className='img-fluid whyicon' alt='why'/>
<h3 className='why-heading'>Global reach and Local Expertise</h3>
<p className='why-sentence-main'>We offer the best of both worlds: global reach and personalised service. This is possible thanks to our large global network and local expertise. Our team of logistics experts is committed to understanding your specific needs and goals and providing custom solutions that meet those needs.</p>
        </div>

        <div className='col-lg-6'>
<img src={reliability} className='img-fluid whyicon' alt='why'/>
<h3 className='why-heading'>Reliability and Efficiency</h3>
<p className='why-sentence-main'>At Golden Solomon, we put dependability and efficiency first. We're proud of the fact that we always deliver goods safely, on time, and in great shape. Our streamlined processes and proactive approach make sure that everything runs smoothly and give our clients peace of mind.</p>
        </div>

        <div className='col-lg-6'>
<img src={compliance} className='img-fluid whyicon' alt='why'/>
<h3 className='why-heading'>Compliance and Risk Management</h3>
<p className='why-sentence-main'>We know how important it is for international trade to have compliance and risk management. Our expert team stays up to date on the newest rules and best practices in the industry to keep your business safe from possible fines and delays and to minimise risk and make sure you're following all international trade laws.</p>
        </div>

        <div className='col-lg-6'>
<img src={expertise} className='img-fluid whyicon' alt='why'/>
<h3 className='why-heading'>Expertise and experience</h3>
<p className='why-sentence-main'>We have years of experience with customs clearance services, so we know how to handle the complicated world of international trade. Our team stays up to date on the latest customs rules and procedures, so we can make sure that your shipments are cleared quickly and correctly.</p>
        </div>


        <div className='col-lg-6'>
<img src={personalized} className='img-fluid whyicon' alt='why'/>
<h3 className='why-heading'>Personalized Service</h3>
<p className='why-sentence-main'>At Golden Solomon, we put an emphasis on one-on-one service and paying close attention to the little things. Our dedicated customs clearance specialists work closely with you to fully understand your needs and provide solutions that are specifically designed to meet your challenges and goals.</p>
        </div>

        <div className='col-lg-6'>
<img src={investing} className='img-fluid whyicon' alt='why'/>
<h3 className='why-heading'>Investing in sustainability</h3>
<p className='why-sentence-main'>We recently bought a high-tech 1000 kg/h bottle recycling washing line, which shows how much we care about the environment. With this cutting-edge technology, we can process and clean plastic bottles quickly and effectively, which helps cut down on plastic waste and promote a circular economy. By putting money into recycling infrastructure, we are reaffirming our commitment to protecting the environment and giving our clients long-term solutions.</p>
        </div>

        <div className='col-lg-6'>
<img src={expanding} className='img-fluid whyicon' alt='why'/>
<h3 className='why-heading'>Expanding Our Reach</h3>
<p className='why-sentence-main'>We are excited to say that we have bought a 3-acre plot of land in Ibadan and are also investing in sustainability. This growth shows that we are committed to meeting our clients' growing needs and spreading our operations across more areas. The new building in Ikorodu will be the centre of our operations. It has all the space and tools we need to improve our processes, make them more efficient, and give our clients the best service possible.</p>
        </div>

        <div className='col-lg-6'>
<img src={means} className='img-fluid whyicon' alt='why'/>
<h3 className='why-heading'>What This Means for You</h3>
<p className='why-sentence-main'>As one of our valued clients, these investments help you in a number of ways. With the new bottle recycling washing line, we can offer better recycling services and help you find long-term ways to deal with plastic waste. Also, because our operations have grown in Ikorodu, we can improve turnaround times, increase capacity, and better meet your needs with more tools and skills.</p>
        </div>

        <div className='col-lg-6'>
<img src={commitment} className='img-fluid whyicon' alt='why'/>
<h3 className='why-heading'>Our Continued Commitment</h3>
<p className='why-sentence-main'>We at Golden Solomon are still dedicated to quality, sustainability, and meeting the needs of our customers. These new investments show that we are committed to growth and new ideas while always upholding the highest standards of quality, dependability, and professionalism in everything we do. We're excited about the new opportunities these changes have created and can't wait to keep going above and beyond your expectations.</p>
        </div>

</div>
</div>

<div className='container'>
    <div className='row'>
        <div className='col-lg-12'>
            <h3 className='our-client'>Our Clients</h3>
            <p className='our-client-sentence'>We are very exceptional to the Business growths of our clients</p>
           

        </div>
    </div>

<div className='container'>
    <div className='row'>
       
        <div className='col-lg-12'>
        <img src={chi} className='img-fluid logo-clients' alt='chi'/>
        <img src={wandel} className='img-fluid logo-clients-2' alt='wandel'/>
        <img src={courtyard} className='img-fluid logo-clients-3' alt='courtyard'/>
        <img src={fmn} className='img-fluid logo-clients-4' alt='fmn'/>
        <img src={pro} className='img-fluid logo-clients-5' alt='pro'/>
        <img src={fries} className='img-fluid logo-clients-6' alt='fries'/>
        <img src={olam} className='img-fluid logo-clients-7' alt='olam'/>
        </div>
        
    </div>
</div>

</div>

<Footer></Footer>
    </div>
  )
}

export default why;